import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';

import {
  defaultFilters,
  FiltersParams,
  SecretKeyItem,
  UpdateClientCorporateItem,
} from './utils';
import { loadItems, updateClientCorporate } from './thunks';
import { ItemSort } from 'entities';

export interface SecretKeyItemState {
  items: SecretKeyItem[];
  totalNumber: number;
  userId: string | null;
  itemsLoading: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  loggingEndReached: boolean;
  page: number;
  rowsPerPage: RowsPerPage;
  isDetailsLoading: boolean;
  itemNotFound: boolean;
  isUpdateCorporate: boolean;
  isClientCorporateUpdating: boolean;
  updateClientCorporateItem: UpdateClientCorporateItem | null;
  filters: FiltersParams;
  sort: ItemSort | null;
  appliedFilters: FiltersParams;
}

export const storeName = 'secretKeyManagement';

const initialState: SecretKeyItemState = {
  items: [] as SecretKeyItem[],
  userId: null,
  itemsLoading: false,
  paginationLoading: false,
  endReached: false,
  loggingEndReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  itemNotFound: false,
  isDetailsLoading: false,
  isUpdateCorporate: false,
  isClientCorporateUpdating: false,
  updateClientCorporateItem: null,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
};

const secretKeyManagementSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.page = 1;
      state.items = [];
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
    },
    updateCorporate(state, action: PayloadAction<UpdateClientCorporateItem>) {
      state.updateClientCorporateItem = action.payload;
      state.isUpdateCorporate = true;
    },
    clearUpdateCorporate(state) {
      state.updateClientCorporateItem = initialState.updateClientCorporateItem;
      state.isUpdateCorporate = initialState.isUpdateCorporate;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = action.payload;
    },
    resetAllItems(state) {
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadItems.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action.payload.data;

        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );

    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });

    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });

    builder.addCase(updateClientCorporate.fulfilled, (state) => {
      state.isClientCorporateUpdating = initialState.isClientCorporateUpdating;
    });

    builder.addCase(updateClientCorporate.pending, (state) => {
      state.isClientCorporateUpdating = true;
    });

    builder.addCase(updateClientCorporate.rejected, (state) => {
      state.isClientCorporateUpdating = initialState.isClientCorporateUpdating;
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  setSort,
  resetAllItems,
  updateCorporate,
  clearUpdateCorporate,
  setFilters,
} = secretKeyManagementSlice.actions;

export default secretKeyManagementSlice.reducer;

export const IncrementAuth = () => (
  <img
    style={{
      width: '25px',
      height: '25px',
    }}
    src={require('../img/business.png')}
    alt={''}
  />
);

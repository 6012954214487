import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';
import {
  AccountHoldersDetails,
  FiltersParams,
  MerchantGetDetails,
  MerchantItem,
  MerchantItemCreate,
  merchantItemCreateInit,
  MerchantsStatusToggleDetails,
  RapidConnectMerchantDetails,
  ToggleMerchantStatus,
} from 'entities/system';

import {
  accountHoldersDetails,
  defaultFilters,
  details,
  rapidConnectMerchantDetails,
} from './utils';
import {
  createMerchant,
  fetchDetails,
  fetchListCountryCode,
  loadItems,
  updateMerchantToggleStatus,
} from './thunks';
import moment from 'moment';
import { ItemSort } from 'entities';
import { ItemAttributes } from 'components/itemAttributeType';

export interface MerchantsState {
  items: MerchantItem[];
  merchantItemCreate: MerchantItemCreate;
  merchantCreateStepNumber: number;
  itemsLoading: boolean;
  itemsLoadingComputed: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  downloadLoading: boolean;
  details: MerchantGetDetails;
  isDetailsLoading: boolean;
  isToggleLoading: boolean;
  itemId: string | null;
  itemNotFound: boolean;
  itemStatus: ToggleMerchantStatus;
  itemPreviewStatus: MerchantsStatusToggleDetails;
  merchantId: string;
  itemStatusChanged: boolean;
  filters: FiltersParams;
  sort: ItemSort | null;
  appliedFilters: FiltersParams;
  rapidConnectMerchantDetails: RapidConnectMerchantDetails;
  openStatusModal: boolean;
  accountHolderDetails: AccountHoldersDetails;
  futureMerchantStatus: boolean | null;
  countryCodes: ItemAttributes[];
  isCountryCodesLoading: boolean;
  isMerchantCreated: boolean;
  isMerchantCreationLoading: boolean;
}

export const storeName = 'sysMerchants';

const initialState: MerchantsState = {
  items: [] as MerchantItem[],
  merchantItemCreate: merchantItemCreateInit,
  merchantCreateStepNumber: 1,
  itemId: null as string | null,
  itemsLoading: false,
  itemsLoadingComputed: false,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  downloadLoading: false,
  details: details,
  isDetailsLoading: false,
  isToggleLoading: false,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
  itemNotFound: false,
  rapidConnectMerchantDetails: rapidConnectMerchantDetails,
  accountHolderDetails: accountHoldersDetails,
  itemStatus: {
    merchantId: '',
    isEnabled: false,
    fromDateTime: null,
  },
  itemPreviewStatus: {
    id: '',
    merchantId: '',
    status: '',
    fromDateTime: '',
  },
  merchantId: '',
  itemStatusChanged: false,
  openStatusModal: false,
  futureMerchantStatus: null,
  countryCodes: [],
  isCountryCodesLoading: false,
  isMerchantCreated: false,
  isMerchantCreationLoading: false,
};

const merchantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    upMerchantCreateStepNumber(state) {
      state.merchantCreateStepNumber = state.merchantCreateStepNumber + 1;
    },
    downMerchantCreateStepNumber(state) {
      state.merchantCreateStepNumber = state.merchantCreateStepNumber - 1;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    setItemId(state, action) {
      state.itemId = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
    },
    setOpenModal(state) {
      state.openStatusModal = !state.openStatusModal;
    },
    setMerchantStatusChanged(state, action) {
      state.itemStatusChanged = action.payload;
    },
    setMerchantStatus(state, action) {
      state.itemStatus = action.payload;
    },
    setMerchantStatusPreview(state, action) {
      state.itemPreviewStatus = action.payload;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.sort = action.payload;
    },
    setFutureMerchantStatus(state, action) {
      state.futureMerchantStatus = action.payload;
    },
    resetFutureMerchantStatus(state) {
      state.futureMerchantStatus = initialState.futureMerchantStatus;
    },
    resetAllItems(state) {
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.merchantCreateStepNumber = initialState.merchantCreateStepNumber;
      state.appliedFilters = initialState.appliedFilters;
      state.rowsPerPage = initialState.rowsPerPage;
      state.totalNumber = initialState.totalNumber;
    },
    setMerchantInfoItemCreate(state, action) {
      state.merchantItemCreate.merchantInfoItem = action.payload;
    },
    setMerchantRapidConnectCreate(state, action) {
      state.merchantItemCreate.merchantRapidConnectItem = action.payload;
    },
    setMerchantNMICreate(state, action) {
      state.merchantItemCreate.merchantNMIItem = action.payload;
    },
    setMerchantAccountHolderCreate(state, action) {
      state.merchantItemCreate.merchantAccountHolderItem = action.payload;
    },
    resetMerchantCreated(state) {
      state.isMerchantCreated = initialState.isMerchantCreated;
    },
    clearMerchantCreate(state) {
      state.merchantItemCreate = initialState.merchantItemCreate;
      state.merchantCreateStepNumber = initialState.merchantCreateStepNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateMerchantToggleStatus.fulfilled, (state, action) => {
      state.itemPreviewStatus = action.payload;
      state.isToggleLoading = false;
      const { fromDateTime } = action.payload;
      state.itemPreviewStatus.fromDateTime =
        fromDateTime !== null ? fromDateTime : moment().toISOString();
    });

    builder.addCase(updateMerchantToggleStatus.pending, (state) => {
      state.isToggleLoading = true;
    });

    builder.addCase(updateMerchantToggleStatus.rejected, (state) => {
      state.isToggleLoading = false;
    });

    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.isDetailsLoading = false;
      state.itemNotFound = false;
      const { merchantDetails } = action.payload;
      state.details = merchantDetails;
    });
    builder.addCase(fetchDetails.pending, (state) => {
      state.isDetailsLoading = true;
    });
    builder.addCase(fetchDetails.rejected, (state) => {
      state.isDetailsLoading = false;
      state.itemNotFound = true;
      state.details = initialState.details;
      state.merchantId = initialState.merchantId;
      state.itemPreviewStatus = initialState.itemPreviewStatus;
      state.accountHolderDetails = initialState.accountHolderDetails;
      state.rapidConnectMerchantDetails =
        initialState.rapidConnectMerchantDetails;
    });

    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: any[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;
        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );
    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
    builder.addCase(
      fetchListCountryCode.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.countryCodes = action.payload;
        state.isCountryCodesLoading = false;
      }
    );
    builder.addCase(fetchListCountryCode.pending, (state) => {
      state.isCountryCodesLoading = true;
    });
    builder.addCase(fetchListCountryCode.rejected, (state) => {
      state.countryCodes = initialState.countryCodes;
      state.isCountryCodesLoading = false;
    });
    builder.addCase(createMerchant.fulfilled, (state) => {
      state.isMerchantCreationLoading = initialState.isMerchantCreationLoading;
      state.isMerchantCreated = true;
    });
    builder.addCase(createMerchant.pending, (state) => {
      state.isMerchantCreationLoading = true;
    });
    builder.addCase(createMerchant.rejected, (state) => {
      state.isMerchantCreationLoading = initialState.isMerchantCreationLoading;
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setApplyFilters,
  setOpenModal,
  resetAllFilters,
  resetAllItems,
  setFutureMerchantStatus,
  resetFutureMerchantStatus,
  setMerchantRapidConnectCreate,
  setMerchantAccountHolderCreate,
  setMerchantNMICreate,
  setMerchantInfoItemCreate,
  upMerchantCreateStepNumber,
  downMerchantCreateStepNumber,
  clearMerchantCreate,
  setSort,
  resetMerchantCreated,
  setFilters,
} = merchantsSlice.actions;

export default merchantsSlice.reducer;

import api from 'api';

const secretKeyList = async (data: any) => {
  return api.post('/client/api-key/list', data);
};

const updateClientCorporate = async (data: any) => {
  return api.post('/client/update/corporate', data);
};

export const userResource = {
  secretKeyList,
  updateClientCorporate,
};

import { Moment } from 'moment';
import { ItemAttributes } from 'components/itemAttributeType';

export type MerchantItem = {
  id: string;
  name: string;
  status: string;
};

export type ToggleMerchantStatus = {
  merchantId: string;
  isEnabled: boolean;
  fromDateTime: Moment | null;
};

export type MerchantsStatusToggleDetails = {
  id: string;
  merchantId: string;
  status: string;
  fromDateTime: string;
};

export type MerchantGetDetails = {
  id: string;
  createdTime: string;
  logoUrl: string | null;
  isAvsEnabled: boolean;
  name: string | null;
  externalMerchantId: string | null;
  programName: string | null;
  programId: string | null;
  processorName: string | null;
  corporateId: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postal: string | null;
  url: string | null;
  timezoneId: string | null;
  accountNumber: string | null;
  routingNumber: string | null;
  username: string | null;
  feePlan: string | null;
  externalIdentifier: string | null;
  locale: string | null;
  currency: string | null;
  contactFirstName: string | null;
  contactLastName: string | null;
  contactPhone: string | null;
  contactEmail: string | null;
  accountType: string | null;
  accountHolderType: string | null;
  merchantType: string | null;
  captureHigherThanAuthed: boolean | null;
  allowPaymentFacilitatorFields: boolean | null;
  updatedTime: string;
  countryCode: string | null;
  accountHolder: AccountHoldersDetails;
  rapidConnectMerchant: RapidConnectMerchantDetails;
  reportsConfiguration: ReportsConfigDetails;
  merchantConfiguration: MerchantsConfigurationDetails;
  merchantStatusDetails: MerchantStatusDetails;
  merchantLimits: MerchantLimitsDetails;
};

export type FiltersParams = {
  status: ItemAttributes[];
  merchantName: string;
  programsIds: ItemAttributes[];
  processor: ItemAttributes[];
};

export type AccountHoldersDetails = {
  countryCode: string; // todo select
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  legal_entity_type: string;
  birthDate: string; // todo update to date select
  billingAddressCountryCode: string; // todo select
  billingAddressSubDivisionCode: string;
  billingAddressCity: string;
  billingAddressPostalCode: string;
  billingAddressAddressLine1: string;
};

export type RapidConnectMerchantDetails = {
  id: string;
  merchantId: string;
  terminalId: string;
  groupId: string;
  datawireId: string;
  primaryServiceUrl: string;
  secondaryServiceUrl: string;
  ecommerceUrl: string;
  merchantCategoryCode: string;
  status: string;
};

export type NmiDetails = {
  accountNumber: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  externalIdentifier: string;
  feePlan: string;
  locale: string;
  merchantId: string;
  postal: string;
  routingNumber: string;
  serviceUrl: string;
  state: string;
  timezoneId: string;
  url: string;
  username: string;
};

export type ReportsConfigDetails = {
  reports: string[];
  storageTypes: string[];
  folderName: string;
  updatedAt: string;
  createdAt: string;
};

export type MerchantStatusDetails = {
  changeStatus: string;
  currentStatus: string;
  fromDateTime: string;
  reason: string;
};

export type MerchantLimitsDetails = {
  //+++sale+++
  //+++Annual value+++
  rollingAnnualTransactionVolumeLimit: number | null;
  rollingAnnualTransactionVolumeLimitNotice: number | null;
  rollingAnnualTransactionVolumeLimitBlock: number | null;
  //--------
  //---daily---
  rolling24hTransactionVolumeLimit: number | null;
  rolling24hTransactionVolumeLimitNotice: number | null;
  rolling24hTransactionVolumeLimitBlock: number | null;
  //----7 days---
  rolling7DaysTransactionVolumeLimit: number | null;
  rolling7DaysTransactionVolumeLimitNotice: number | null;
  rolling7DaysTransactionVolumeLimitBlock: number | null;
  //---30 days---
  rolling30DaysTransactionVolumeLimit: number | null;
  rolling30DaysTransactionVolumeLimitNotice: number | null;
  rolling30DaysTransactionVolumeLimitBlock: number | null;
  //+++refund+++
  rollingAnnualRefundVolumeLimit: number | null;
  rollingAnnualRefundVolumePercentLimit: number | null;
  rollingAnnualRefundLimitNotice: number | null;
  rollingAnnualRefundPercentLimitNotice: number | null;
  rollingAnnualRefundLimitBlock: number | null;
  rollingAnnualRefundPercentLimitBlock: number | null;
  //===============
  //+++Chargeback+++
  rollingAnnualChargebackVolumeLimit: number | null;
  rollingAnnualChargebackVolumePercentLimit: number | null;
  rollingAnnualChargebackLimitNotice: number | null;
  rollingAnnualChargebackPercentLimitNotice: number | null;
  rollingAnnualChargebackPercentLimitBlock: number | null;
  rollingAnnualChargebackLimitBlock: number | null;
  //===============
  rolling24hRefundVolumeLimitNotice: number | null;
  rolling24hRefundVolumeLimitBlock: number | null;
  rolling7DaysRefundVolumeLimitNotice: number | null;
  rolling7DaysRefundVolumeLimitBlock: number | null;
  rolling30DaysRefundVolumeLimitNotice: number | null;
  rolling30DaysRefundVolumeLimitBlock: number | null;
};

export type MerchantsConfigurationDetails = {
  clientVertical: string;
  creditLimit: number | null;
  approvalDate: string | null;
  expiryDate: string | null;
  internalRiskRating: string | null;
  merchantAdvisedLowestTicket: number | null;
  merchantAdvisedAverageTicket: number | null;
  merchantAdvisedHighestTicket: number | null;
  merchantAdvisedDailyTransactionNumber: number | null;
  merchantAdvisedDailyTransactionVolume: number | null;
  merchantAdvisedRefundRatio: number | null;
  merchantAdvisedChargebackRatio: number | null;
  merchantAdvisedChargebackDays: number | null;
  merchantAdvisedDeliveryDays: number | null;
  expectedMonthlyVolume: number | null;
  merchantHighMonthlyVolumePercentToFlag: number | null;
  merchantLowMonthlyVolumePercentToFlag: number | null;
  status: string;
  expectedMonthlyTransactionNumber: number | null;
  highRiskAccount: boolean;
  highRiskAccountDescription: string;
  createdAt: string;
  merchantDailyTransactionNumberLimitPercent: number | null;
  merchantDailyTransactionVolumeLimitPercent: number | null;
  dailyTicketAverage: number | null;
  dailyTicketVolumeLimitPercent: number | null;
  advisedDailyRefundsNumber: number | null;
  advisedDailyRefundsVolume: number | null;
  advisedDailyRefundsVolumeRatio: number | null;
  advisedDailyRefundsNumberLimitPercent: number | null;
  advisedDailyChargebacksNumberRatio: number | null;
  advisedDailyChargebacksNumber: number | null;
  advisedDailyChargebacksCountLimitPercent: number | null;
  advisedDailyChargebacksVolume: number | null;
  updatedAt: string;
  expectedMonthlyTransactionsNumber: number | null;
  advisedDailyRefundsNumberRatio: number | null;
  merchantAdvisedLowestTicketCount: number | null;
  advisedRepeatedCardNumberCount: number | null;
  advisedRepeatedCardNumberVolume: number | null;
  advisedRepeatedBankAccountNumberCount: number | null;
  advisedRepeatedBankAccountNumberVolume: number | null;
  advisedRepeatedIpAddressCount: number | null;
  advisedRepeatedIpAddressVolume: number | null;
  advisedRepeatedCardNumberUtilizedCount: number | null;
  advisedRepeatedCardNumberUtilizedVolume: number | null;
  advisedBeginningOperatingHour: string | null;
  advisedEndingOperatingHour: string | null;
  advisedTransactionsOutsideOperatingHoursCount: number | null;
  advisedTransactionsOutsideOperatingHoursVolume: number | null;
  advisedRejectedCheckedTransactionVolume: number | null;
  advisedRejectedCheckedTransactionCount: number | null;
};

export type MerchantInfoItem = {
  merchantName: string;
  program: ItemAttributes | null;
  clientId: string;
  url: string | null;
  currency: ItemAttributes | null;
  stateUS: ItemAttributes | null;
  state: string;
  address1: string;
  address2: string;
  city: string;
  postal: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  contactEmail: string;
  merchantExternalId: string;
  countryCode: ItemAttributes | null;
  corporate: ItemAttributes | null;
  gateway: ItemAttributes | null;
};

export const merchantInfoItemInit = {
  merchantName: '',
  program: null,
  url: null,
  clientId: '',
  merchantExternalId: '',
  currency: null,
  countryCode: null,
  stateUS: null,
  state: '',
  address1: '',
  address2: '',
  city: '',
  postal: '',
  contactFirstName: '',
  contactLastName: '',
  contactPhone: '',
  contactEmail: '',
  corporate: null,
  gateway: null,
};

export type MerchantRapidConnectItem = {
  merchantExternalId: string;
  isAvsEnabled: ItemAttributes;
  rapidConnectTerminalId: string;
  rapidConnectGroupId: string;
  rapidConnectPrimaryServiceUrl: string;
  rapidConnectSecondaryServiceUrl: string;
  rapidConnectMerchantCategoryCode: string;
  rapidConnectEcommerceUrl: string;
};

export const merchantRapidConnectItemInit = {
  merchantExternalId: '',
  rapidConnectTerminalId: '',
  isAvsEnabled: { label: 'Enable', value: 'true' },
  rapidConnectGroupId: '',
  rapidConnectPrimaryServiceUrl: '',
  rapidConnectSecondaryServiceUrl: '',
  rapidConnectMerchantCategoryCode: '',
  rapidConnectEcommerceUrl: '',
};

export type MerchantNMIItem = {
  accountNumber: string | null;
  routingNumber: string | null;
  username: string;
  feePlan: string;
  locale: ItemAttributes | null;
  timezoneId: ItemAttributes | null;
  accountType: ItemAttributes | null;
  accountHolderType: ItemAttributes | null;
  merchantType: ItemAttributes | null;
  captureHigherThanAuthed: ItemAttributes | null;
  allowPaymentFacilitatorFields: ItemAttributes | null;
  isAvsEnabled: ItemAttributes | null;
};

export const merchantNMIItemInit = {
  nmiServiceUrl: '',
  nmiToken: '',
  accountNumber: null,
  routingNumber: null,
  username: '',
  feePlan: '',
  locale: null,
  timezoneId: null,
  accountType: null,
  accountHolderType: null,
  merchantType: null,
  captureHigherThanAuthed: null,
  allowPaymentFacilitatorFields: null,
  isAvsEnabled: { label: 'Disable', value: 'false' },
};

export type MerchantAccountHolderItem = {
  accountHolderCountryCode: ItemAttributes | null;
  accountHolderFirstName: string;
  accountHolderLastName: string;
  accountHolderEmail: string;
  accountHolderPhone: string;
  accountHolderBirthdate: string;
  accountHolderBillingAddressCountryCode: ItemAttributes | null;
  accountHolderBillingAddressSubDivisionCode: string;
  accountHolderBillingAddressCity: string;
  accountHolderBillingAddressPostalCode: string;
  accountHolderBillingAddressAddressLine1: string;
  legalEntityType: string;
};

export const merchantAccountHolderItemInit = {
  accountHolderCountryCode: null,
  accountHolderFirstName: '',
  accountHolderLastName: '',
  accountHolderEmail: '',
  accountHolderPhone: '',
  accountHolderBirthdate: '',
  accountHolderBillingAddressCountryCode: null,
  accountHolderBillingAddressSubDivisionCode: '',
  accountHolderBillingAddressCity: '',
  accountHolderBillingAddressPostalCode: '',
  accountHolderBillingAddressAddressLine1: '',
  legalEntityType: '',
};

export type MerchantItemCreate = {
  merchantInfoItem: MerchantInfoItem;
  merchantRapidConnectItem: MerchantRapidConnectItem;
  merchantNMIItem: MerchantNMIItem;
  merchantAccountHolderItem: MerchantAccountHolderItem;
};

export const merchantItemCreateInit = {
  merchantInfoItem: merchantInfoItemInit,
  merchantRapidConnectItem: merchantRapidConnectItemInit,
  merchantNMIItem: merchantNMIItemInit,
  merchantAccountHolderItem: merchantAccountHolderItemInit,
};

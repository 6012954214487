import { Input } from 'components/inputs';
import { useState } from 'react';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { MerchantCreateSteps } from './MerchantCreateSteps';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getMerchantRapidConnectItemStatus } from '../selectors';
import { MerchantRapidConnectItem } from 'entities/system';
import { setMerchantRapidConnectCreate } from '../merchantsSlice';
import { SelectNoController } from '../../../../components/inputs/SelectNoController';
import { conditionEnableResultList } from '../utils';

export const MerchantRapidConnectComponent = () => {
  const dispatch = useAppDispatch();

  const merchantRapidConnectItem = useAppSelector(
    getMerchantRapidConnectItemStatus
  );
  const [rapidConnectInfo, setRapidConnectInfo] =
    useState<MerchantRapidConnectItem>(merchantRapidConnectItem);

  const setRapidConnectInfoItems = (value: any, name: string) => {
    setRapidConnectInfo({
      ...rapidConnectInfo,
      [name]: value,
    });
  };

  const setRapidConnect = () =>
    dispatch(setMerchantRapidConnectCreate(rapidConnectInfo));

  const isDisabled =
    !rapidConnectInfo.merchantExternalId.trim().length ||
    !rapidConnectInfo.rapidConnectTerminalId.trim().length ||
    !rapidConnectInfo.rapidConnectGroupId.trim().length ||
    !rapidConnectInfo.rapidConnectPrimaryServiceUrl.trim().length ||
    !rapidConnectInfo.rapidConnectEcommerceUrl.trim().length ||
    !rapidConnectInfo.rapidConnectMerchantCategoryCode.trim().length;

  return (
    <>
      <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-4 3xl:grid-cols-4 items-center justify-items-start my-4 sm:m-4 sm:p-3">
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Merchant External Id" />}
            placeholder="Merchant External Id"
            defaultValue={rapidConnectInfo.merchantExternalId}
            onChange={(val) =>
              setRapidConnectInfoItems(
                val.currentTarget.value.trim(),
                'merchantExternalId'
              )
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Terminal Id" />}
            placeholder="Terminal Id"
            onChange={(val) =>
              setRapidConnectInfoItems(
                val.currentTarget.value.trim(),
                'rapidConnectTerminalId'
              )
            }
            defaultValue={merchantRapidConnectItem.rapidConnectTerminalId}
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Group Id" />}
            placeholder="Group Id"
            onChange={(val) =>
              setRapidConnectInfoItems(
                val.currentTarget.value.trim(),
                'rapidConnectGroupId'
              )
            }
            defaultValue={merchantRapidConnectItem.rapidConnectGroupId}
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Primary Service Url" />}
            placeholder="Primary Service Url"
            onChange={(val) =>
              setRapidConnectInfoItems(
                val.currentTarget.value.trim(),
                'rapidConnectPrimaryServiceUrl'
              )
            }
            defaultValue={
              merchantRapidConnectItem.rapidConnectPrimaryServiceUrl
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label="Secondary Service Url"
            placeholder="Secondary Service Url"
            onChange={(val) =>
              setRapidConnectInfoItems(
                val.currentTarget.value.trim(),
                'rapidConnectSecondaryServiceUrl'
              )
            }
            defaultValue={
              merchantRapidConnectItem.rapidConnectSecondaryServiceUrl
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent labelText="Merchant Category Code" />
            }
            placeholder="Merchant Category Code"
            onChange={(val) =>
              setRapidConnectInfoItems(
                val.currentTarget.value.trim(),
                'rapidConnectMerchantCategoryCode'
              )
            }
            defaultValue={
              merchantRapidConnectItem.rapidConnectMerchantCategoryCode
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={<RequiredFieldComponent labelText="Ecommerce Url" />}
            placeholder="Ecommerce Url"
            onChange={(val) =>
              setRapidConnectInfoItems(
                val.currentTarget.value.trim(),
                'rapidConnectEcommerceUrl'
              )
            }
            defaultValue={merchantRapidConnectItem.rapidConnectEcommerceUrl}
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Address Verification System (AVS)"
                isRequired={false}
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            isClearable={false}
            placeholder="Address Verification System (AVS)"
            name="isAvsEnabled"
            onChange={(val) => setRapidConnectInfoItems(val, 'isAvsEnabled')}
            height="2.2rem"
            options={conditionEnableResultList}
            value={
              rapidConnectInfo.isAvsEnabled?.value
                ? rapidConnectInfo.isAvsEnabled
                : null
            }
          />
        </div>
      </div>
      <MerchantCreateSteps
        isNextStepDisabled={isDisabled}
        onClick={setRapidConnect}
      />
    </>
  );
};

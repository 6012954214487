/* eslint-disable @typescript-eslint/ban-types */
import {
  AuthorizationActions,
  AuthorizationStatus,
  AvsResponse,
  CardType,
  CcvResult,
  ListFilters,
  TransactionType,
} from 'entities';
import { ItemAttributes } from 'components/itemAttributeType';
import moment from 'moment-timezone';
import { dateTimeSecondsFormat } from '../../../components/dateTimeFormatUtil';
import { enumKeyValue } from '../../../utils/utilClass';

export type LoadItemsOptions = {
  filters: ListFilters;
};

export type AuthAction = {
  isAction: boolean;
  actionName: string;
  onActionClick?: () => void;
};

export const filterValues = (items: ItemAttributes[]) =>
  items.map((item) => item.value);

export const listRequestPayload = (
  options: LoadItemsOptions,
  dateZone: any
) => ({
  filters: {
    transactionDate: options.filters.transactionDate.from
      ? {
          from: moment(options.filters.transactionDate.from)
            .tz(dateZone)
            .startOf('day')
            .format(dateTimeSecondsFormat),
          to: moment(
            options.filters.transactionDate?.to
              ? options.filters.transactionDate.to
              : options.filters.transactionDate.from
          )
            .tz(dateZone)
            .endOf('day')
            .format(dateTimeSecondsFormat),
        }
      : null,
    cardType: options.filters.cardTypes.length
      ? filterValues(options.filters.cardTypes)
      : null,
    cardLast4: options.filters.cardLastFour || null,
    nameOnCard: options.filters.nameOnCard || null,
    id: options.filters.id || null,
    reference: options.filters.merchantExternalReference || null,
    authorizationCode: options.filters.authorizationCode || null,
    stan: options.filters.stan || null,
    amount: options.filters.amount,
    merchantIds:
      options.filters.merchants.length > 0
        ? filterValues(options.filters.merchants)
        : null,
    status:
      options.filters.status.length > 0
        ? filterValues(options.filters.status)
        : null,
    transactionType:
      options.filters.transactionTypes.length > 0
        ? filterValues(options.filters.transactionTypes)
        : null,
  },
});

interface MappedDataProps {
  amount: number;
  authorizationId: string;
  cardBrand: CardType;
  currencyCode: string;
  destinationId: string;
  id: string;
  localTime: string;
  posEntryMode: string;
  reference: string;
  responseCode: string;
  stan: string;
  terminalId: string;
  transmissionTime: string;
  settledDate: string;
  merchantId: string;
  merchantName: string;
  clientName: string;
  transactionType: TransactionType;
  cardNumber: string;
  user: string;
  status: AuthorizationStatus;
}

export const mappedData = (data: any) => {
  return data.map((item: MappedDataProps) => ({
    id: item.id,
    localDate: item.localTime,
    transmissionDate: item.transmissionTime,
    stan: item.stan,
    merchantExternalReference: item.reference,
    terminalId: item.terminalId,
    merchantId: item.merchantId,
    merchantName: item.merchantName,
    submittedBy: item.clientName,
    posEntryMode: item.posEntryMode,
    transactionAmount: item.amount,
    transactionCurrency: item.currencyCode,
    cardType: item.cardBrand,
    responseCode: item.responseCode,
    authId: item.authorizationId,
    transactionType: item.transactionType,
    cardNumber: item.cardNumber,
    status: item.status,
    settledDate: item.settledDate,
  }));
};

interface AuthDetailsResponseProps {
  additionalResponseData: string;
  authId: string;
  avsResponse: AvsResponse;
  banknetData: string;
  billingAddress?: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    postalCode: string;
    region: string;
  };
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardHolder: string;
  cardNumber: string;
  cardType: CardType;
  ccvResultCode: CcvResult;
  localDate: string;
  merchantExternalReference: string;
  merchantName: string;
  merchantOrderNumber: string;
  merchantRefNumber: string;
  posEntryMode: string;
  responseCode: string;
  stan: string;
  subMerchantId: string;
  terminalId: string;
  user: string;
  transactionAmount: number;
  refundedAmount: number;
  transactionCurrency: string;
  transactionType: TransactionType;
  transmissionDate: string;
  updatedAt: string;
  incrementalAmount: number;
  captureAmount: number;
  partialReversalAmount: number;
  status: AuthorizationStatus;
  request: Object;
  response: Object;
  availableAction: AuthorizationActions;
  childTransaction: ChildTransactionResponse[];
}

export interface ChildTransactionResponse {
  id: string;
  type: string;
  status: AuthorizationStatus;
  amount: number;
  createdAt: string;
}

export const mappedAuthDetails = ({
  data,
}: {
  data: AuthDetailsResponseProps;
}) => {
  return {
    terminalId: data.terminalId,
    merchantId: data.subMerchantId,
    merchantName: data.merchantName,
    cardType: data.cardType,
    country: data.billingAddress?.country,
    zip: data.billingAddress?.postalCode,
    cardNumber: data.cardNumber,
    nameOnCard: data.cardHolder,
    cardExpirationMonth: data.cardExpirationMonth,
    cardExpirationYear: data.cardExpirationYear,
    billingAddress:
      (data.billingAddress?.country
        ? data.billingAddress?.country + ', '
        : '') +
      (data.billingAddress?.region ? data.billingAddress?.region + ', ' : '') +
      (data.billingAddress?.city ? data.billingAddress?.city + ', ' : '') +
      (data.billingAddress?.postalCode
        ? data.billingAddress?.postalCode + ', '
        : '') +
      (data.billingAddress?.addressLine1 || '') +
      ' ' +
      (data.billingAddress?.addressLine2 || ''),
    transactionType: data.transactionType,
    localDate: data.localDate,
    transmissionDate: data.transmissionDate,
    updatedAt: data.updatedAt,
    incrementalAmount: data.incrementalAmount,
    partialReversalAmount: data.partialReversalAmount,
    captureAmount: data.captureAmount,
    posEntryMode: data.posEntryMode,
    transactionAmount: data.transactionAmount,
    refundedAmount: data.refundedAmount,
    transactionCurrency: data.transactionCurrency,
    stan: data.stan,
    merchantRefNumber: data.merchantRefNumber,
    merchantOrderNumber: data.merchantOrderNumber,
    merchantExternalReference: data.merchantExternalReference,
    avsResponse: data.avsResponse,
    ccvResult: data.ccvResultCode,
    banknetData: data.banknetData,
    responseCode: data.responseCode,
    authId: data.authId,
    additionalResponseData: data.additionalResponseData,
    status: data.status,
    request: data.request,
    response: data.response,
    user: data.user,
    availableAction: data.availableAction,
    childTransaction: data.childTransaction,
  };
};

export const transactionDetails = {
  terminalId: '',
  merchantId: '',
  merchantName: '',
  cardType: '',
  country: '',
  zip: '',
  cardNumber: '',
  nameOnCard: '',
  cardExpirationMonth: '',
  cardExpirationYear: '',
  billingAddress: '',
  transactionType: '',
  localDate: '',
  transmissionDate: '',
  updatedAt: '',
  incrementalAmount: 0,
  partialReversalAmount: 0,
  captureAmount: 0,
  posEntryMode: '',
  transactionAmount: 0,
  refundedAmount: 0,
  transactionCurrency: '',
  stan: '',
  user: '',
  merchantRefNumber: '',
  merchantOrderNumber: '',
  merchantExternalReference: '',
  avsResponse: '',
  ccvResult: '',
  banknetData: '',
  responseCode: '',
  authId: '',
  additionalResponseData: '',
  request: {},
  response: {},
  status: '',
  availableAction: [],
  childTransaction: [],
};

export enum RefundType {
  REFUND = 'Refund',
  PARTIAL_REFUND = 'Partial refund',
}
export const refundTypeItems: ItemAttributes[] = [
  {
    label: RefundType.REFUND,
    value: enumKeyValue(RefundType, RefundType.REFUND),
  },
  {
    label: RefundType.PARTIAL_REFUND,
    value: enumKeyValue(RefundType, RefundType.PARTIAL_REFUND),
  },
];

export const countDecimalPlaces = (number: number) => {
  const stringNumber = number?.toString();
  const decimalPart = stringNumber?.split('.')[1];
  return decimalPart ? decimalPart.length : 0;
};

import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { LayoutList } from 'layouts/list/LayoutList';
import { ItemSort } from 'entities';

import {
  getFilters,
  getIsUpdateCorporateLoading,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
  getUpdateCorporateItem,
  isUpdateCorporateModal,
} from '../selectors';
import {
  clearUpdateCorporate,
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../secretKeyManagementSlice';
import { Filters } from './filters/Filters';
import { useColumns } from './useColumns';
import { SecretKeyItem } from '../utils';
import ModalDialog from '@mui/joy/ModalDialog';
import { ModalClose } from '@mui/joy';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/joy/DialogContent';
import { SelectController } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import DialogActions from '@mui/joy/DialogActions';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/joy/Modal';
import { ItemAttributes } from 'components/itemAttributeType';
import { useForm } from 'react-hook-form';
import { CorporateIcon } from 'components/icons/CorporateIcon';
import { corporateList } from 'api/corporates';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateClientCorporate } from '../thunks';

export const SecretKeyManagementList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const items: SecretKeyItem[] = useAppSelector(getItems);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const filters: any = useAppSelector(getFilters);
  const isUpdateCorporate: any = useAppSelector(isUpdateCorporateModal);
  const updateClientCorporateItem: any = useAppSelector(getUpdateCorporateItem);
  const isClientCorporateUpdating: any = useAppSelector(
    getIsUpdateCorporateLoading
  );

  const tableRef = useRef<HTMLDivElement>(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      corporateItem: undefined as unknown as ItemAttributes,
    },
  });

  useEffect(() => {
    setValue('corporateItem', updateClientCorporateItem?.corporateItem);
  }, [setValue, updateClientCorporateItem?.corporateItem]);

  const handleClickClose = () => {
    dispatch(clearUpdateCorporate());
    reset();
    setValue('corporateItem', undefined as unknown as ItemAttributes);
  };

  const columns: ColumnProps[] = useColumns();

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const [modalConfirmCorporateOpen, setModalConfirmCorporateOpen] =
    useState(false);

  const save = (data: any) => {
    dispatch(
      updateClientCorporate({
        apiKeyId: updateClientCorporateItem.apiKeyId,
        corporateId: data.corporateItem.value,
      })
    )
      .unwrap()
      .then(() => {
        location.reload();
      })
      .catch(() => {
        setModalConfirmCorporateOpen(false);
      });
  };

  const isFilterSelected =
    filters.enabled.length ||
    filters.keyPrefix?.length ||
    filters.businessName?.length ||
    filters.type?.length ||
    filters.companyName?.length;

  return (
    <>
      <LayoutList title={t('secretKeyManagement.list.secretKeyList')}>
        <TableCard
          tableRef={tableRef}
          loading={itemsLoadingComputed}
          columns={columns}
          items={items}
          totalNumber={totalNumber}
          filterApply={
            isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
          }
          setSort={setCurrentSort}
          onRowClick={undefined}
          onHover={false}
          pagination={pagination}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          filters={<Filters />}
        />
      </LayoutList>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isUpdateCorporate}
        onClose={() => handleClickClose()}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <ModalClose onClick={() => handleClickClose()} />
          <DialogTitle>
            <CorporateIcon />
            Corporate change
          </DialogTitle>
          <Divider />

          <DialogContent>
            <div className="mb-4">
              <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem] p-2">
                <SelectController
                  label={
                    <RequiredFieldComponent
                      labelText="Select corporate"
                      className="font-medium tracking-wide text-xs text-black"
                    />
                  }
                  height="2.5rem"
                  name="corporateItem"
                  control={control}
                  required
                  loadOptions={corporateList}
                  closeMenuOnSelect
                  error={errors.corporateItem}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="w-full grid grid-cols-2 gap-2 p-2">
              <div className="w-full">
                <Button
                  color="primary"
                  variant="outlined"
                  className="w-full"
                  onClick={() => handleClickClose()}
                >
                  Cancel
                </Button>
              </div>
              <div className="w-full">
                <Button
                  type="submit"
                  className="w-full"
                  onClick={() => setModalConfirmCorporateOpen(true)}
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </div>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalConfirmCorporateOpen}
        onClose={() => setModalConfirmCorporateOpen(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <ModalClose onClick={() => setModalConfirmCorporateOpen(false)} />
          <DialogTitle>
            <CorporateIcon />
            Corporate change confirmation
          </DialogTitle>
          <Divider />
          <form onSubmit={handleSubmit(save)}>
            <DialogContent>
              <div className="mb-4">
                <p>
                  <span className="text-orange-700">Attention</span>: You are
                  about to make changes to core corporate data.
                </p>{' '}
                <p>Please verify that this is the intended action.</p>{' '}
                <p>Do you wish to proceed?</p>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="w-full grid grid-cols-2 gap-2 p-2">
                <div className="w-full">
                  <Button
                    color="primary"
                    variant="outlined"
                    className="w-full"
                    onClick={() => setModalConfirmCorporateOpen(false)}
                    startIcon={<CancelIcon />}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-full">
                  <LoadingButton
                    type="submit"
                    className="w-full"
                    color="success"
                    variant="contained"
                    loading={isClientCorporateUpdating}
                    startIcon={<DoneOutlineIcon />}
                  >
                    Confirm
                  </LoadingButton>
                </div>
              </div>
            </DialogActions>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
};

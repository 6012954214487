import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import {
  dateTimeFormat,
  dateTimeZone,
  getProfilePermission,
  isAdminSelector,
} from 'domain/account/selectors';
import {
  getIsOperationLoading,
  getItemsNotFound,
  getTransactionDetailsLoading,
  getTransactionItemDetails,
} from '../../selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import React, { useEffect, useRef, useState } from 'react';
import {
  captureProcess,
  fetchAuthorizationDetails,
  incrementProcess,
  markAsRefundThunk,
  partialReverseProcess,
  voidProcess,
} from '../../thunks';
import { PageHeader } from 'components/PageHeader';
import {
  Button,
  ButtonGroup,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Grow,
  MenuItem,
  MenuList,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { RectLoader } from 'components/RectLoader';
import { ItemView } from 'components/grid/ItemView';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { JsonViewer } from '@textea/json-viewer';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { IconJSON } from 'components/icons/IconJSON';
import { CardBrandBadge } from './CardBrandBadge';
import { AuthorizationStatusBadge } from '../AuthorizationStatusBadge';
import {
  AuthorizationActions,
  AuthorizationStatus,
  AuthorizationType,
} from '../../../../../entities';
import LoadingButton from '@mui/lab/LoadingButton';
import { UserPermissions } from 'enums/Permissions';
import ModalDialog from '@mui/joy/ModalDialog';
import { ModalClose } from '@mui/joy';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import { useForm } from 'react-hook-form';
import {
  AuthAction,
  countDecimalPlaces,
  RefundType,
  refundTypeItems,
} from '../../utils';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { Input, SelectController } from 'components/inputs';
import { ItemAttributes } from 'components/itemAttributeType';
import ReportIcon from '@mui/icons-material/Report';
import ErrorIcon from '@mui/icons-material/Error';
import { SuccessVerify } from 'components/icons/SuccessVerify';
import { VoidVerify } from 'components/icons/VoidVerify';
import { IncrementAuth } from 'components/icons/IncrementAuth';
import { Rollback } from 'components/icons/Rollback';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export const PreviewDetails = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useSelector(isAdminSelector);
  const itemNotFound = useSelector(getItemsNotFound);
  const isDetailsLoading = useSelector(getTransactionDetailsLoading);
  const itemDetail = useSelector(getTransactionItemDetails);
  const isOperationLoading = useSelector(getIsOperationLoading);
  const permissions: any = useAppSelector(getProfilePermission);
  const history: any = useHistory();
  const { id } = useParams<{ id: string }>();
  const transactionId = Base64.decode(id);
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const timeFormat = (value: string | null) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#a3a3a3',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const transactionAmountGet =
    itemDetail?.transactionType === AuthorizationType.AUTHORIZATION
      ? Number(itemDetail.captureAmount?.toFixed(2))
      : Number(itemDetail.transactionAmount.toFixed(2));

  const refundResult = Number(
    (transactionAmountGet - itemDetail.refundedAmount).toFixed(2)
  );

  const captureResult = Number(itemDetail.transactionAmount.toFixed(2));

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      refundType: undefined as unknown as ItemAttributes,
      amount: null as unknown as number,
      captureAmount: null as unknown as number,
      reversalAmount: 0.0,
      incrementalAmount: 0.0,
    },
  });

  useEffect(() => {
    dispatch(fetchAuthorizationDetails({ id: transactionId }));
  }, [transactionId, dispatch]);

  useEffect(() => {
    setValue('amount', null as unknown as number);
  }, [refundResult, setValue]);

  const { refundType, amount } = watch();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalCaptureOpen, setModalCaptureOpen] = useState(false);
  const [modalIncrementOpen, setModalIncrementOpen] = useState(false);
  const [modalReversalOpen, setModalReversalOpen] = useState(false);
  const [modalVoidOpen, setModalVoidOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageType, setErrorMessageType] = useState('');

  const markAsRefund = () => {
    setModalOpen(true);
  };

  const markAsIncremental = () => {
    setModalIncrementOpen(true);
  };

  const markAsReversal = () => {
    setModalReversalOpen(true);
  };

  const markAsCapture = () => {
    setModalCaptureOpen(true);
  };

  const markAsVoid = () => {
    setModalVoidOpen(true);
  };

  const isTransactionVoid: boolean =
    itemDetail.availableAction.includes(AuthorizationActions?.VOID) &&
    permissions.includes(UserPermissions.VIRTUAL_TERMINAL_VOID_PAYMENT);

  const isTransactionCaptured: boolean =
    itemDetail.availableAction.includes(AuthorizationActions?.CAPTURE) &&
    permissions.includes(UserPermissions.VIRTUAL_TERMINAL_CAPTURE_PAYMENT);

  const isTransactionRefunded: boolean =
    itemDetail.availableAction.includes(AuthorizationActions?.REFUND) &&
    permissions.includes(UserPermissions.VIRTUAL_TERMINAL_REFUND_PAYMENT);

  const isTransactionIncrement: boolean = itemDetail.availableAction.includes(
    AuthorizationActions?.INCREMENTAL_AUTHORIZATION
  );

  const isTransactionPartialReversal: boolean =
    itemDetail.availableAction.includes(AuthorizationActions?.PARTIAL_REVERSAL);

  const optionsAuthAction: AuthAction[] = [
    {
      isAction: isTransactionIncrement,
      actionName: 'Increment Authorization',
      onActionClick: markAsIncremental,
    },
    {
      isAction: isTransactionPartialReversal,
      actionName: 'Partial Reverse',
      onActionClick: markAsReversal,
    },
    {
      isAction: isTransactionCaptured,
      actionName: 'Capture',
      onActionClick: markAsCapture,
    },
    {
      isAction: isTransactionRefunded,
      actionName: 'Refund',
      onActionClick: markAsRefund,
    },
    {
      isAction: isTransactionVoid,
      actionName: 'Void',
      onActionClick: markAsVoid,
    },
  ].filter((value) => value.isAction) as AuthAction[];

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  const [isCaptureEditable, setCaptureEditable] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCaptureEditable(event.target.checked);
  };

  const handleClick = () => {
    if (selectedIndex !== undefined) {
      optionsAuthAction[selectedIndex]?.onActionClick?.();
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const backButton = () => {
    if (pathname.includes('transaction-management/virtual-terminal/details')) {
      return history.push('/transaction-management/virtual-terminal');
    }
    if (pathname.includes('transaction-management/authorizations')) {
      return history.push('/transaction-management/authorizations');
    }
  };

  const refundLinkRedirect = (value: string) => {
    if (pathname.includes('transaction-management/virtual-terminal/details')) {
      return history.push(
        `/transaction-management/virtual-terminal/details/${Base64.encodeURI(value)}`
      );
    }
    if (pathname.includes('transaction-management/authorizations')) {
      return history.push(
        `/transaction-management/authorizations/${Base64.encodeURI(value)}`
      );
    }
  };

  const handleClickClose = () => {
    setModalOpen(false);
    reset();
    setValue('refundType', undefined as unknown as ItemAttributes);
    setValue('amount', null as unknown as number);
  };

  const handleClickErrorClose = () => {
    setModalErrorOpen(false);
    setValue('refundType', undefined as unknown as ItemAttributes);
    setValue('amount', null as unknown as number);
  };

  const handleClickCaptureClose = () => {
    setModalCaptureOpen(false);
    setValue('captureAmount', null as unknown as number);
  };

  const handleClickIncrementClose = () => {
    setModalIncrementOpen(false);
    setValue('incrementalAmount', 0.0);
  };

  const handleClickReversalClose = () => {
    setModalReversalOpen(false);
    setValue('reversalAmount', 0.0);
  };

  const handleClickVoidClose = () => {
    setModalVoidOpen(false);
  };

  const save = (data: any) => {
    const amountResult =
      data.refundType.label === RefundType.PARTIAL_REFUND ? data.amount : null;
    dispatch(
      markAsRefundThunk({
        originalTransactionId: transactionId,
        amount: amountResult,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchAuthorizationDetails({ id: transactionId }));
        setSelectedIndex(undefined);
        setValue('refundType', undefined as unknown as ItemAttributes);
        setValue('amount', null as unknown as number);
        setModalOpen(false);
      })
      .catch((error: any) => {
        setErrorMessage(error?.message ? error.message : error?.code);
        setErrorMessageType('refund');
        setModalOpen(false);
        setValue('refundType', undefined as unknown as ItemAttributes);
        setValue('amount', null as unknown as number);
        setModalErrorOpen(true);
      });
  };

  const saveCapture = (data: any) => {
    dispatch(
      captureProcess({
        originalTransactionId: transactionId,
        amount: data.captureAmount,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchAuthorizationDetails({ id: transactionId }));
        setModalOpen(false);
        setSelectedIndex(undefined);
        setValue('captureAmount', null as unknown as number);
      })
      .catch((error: any) => {
        setErrorMessage(error?.message ? error.message : error?.code);
        setErrorMessageType('capture');
        setModalCaptureOpen(false);
        setModalErrorOpen(true);
        setValue('captureAmount', null as unknown as number);
      });
  };

  const saveIncrement = (data: any) => {
    dispatch(
      incrementProcess({
        originalTransactionId: transactionId,
        amount: data.incrementalAmount,
      })
    )
      .unwrap()
      .then((response: any) => {
        history.push(
          `/transaction-management/authorizations/${Base64.encodeURI(response.data.id)}`
        );
        setModalIncrementOpen(false);
        setSelectedIndex(undefined);
        setValue('incrementalAmount', 0.0);
      })
      .catch((error: any) => {
        setErrorMessage(error?.message ? error.message : error?.code);
        setErrorMessageType('authorization increment');
        setModalIncrementOpen(false);
        setModalErrorOpen(true);
        setValue('incrementalAmount', 0.0);
      });
  };

  const savePartialReversal = (data: any) => {
    dispatch(
      partialReverseProcess({
        originalTransactionId: transactionId,
        amount: data.reversalAmount,
      })
    )
      .unwrap()
      .then((response: any) => {
        history.push(
          `/transaction-management/authorizations/${Base64.encodeURI(response.data.id)}`
        );
        setModalReversalOpen(false);
        setSelectedIndex(undefined);
        setValue('reversalAmount', 0.0);
      })
      .catch((error: any) => {
        setErrorMessage(error?.message ? error.message : error?.code);
        setErrorMessageType('partial reverse');
        setModalReversalOpen(false);
        setModalErrorOpen(true);
        setValue('reversalAmount', 0.0);
      });
  };

  const saveVoid = () => {
    dispatch(voidProcess({ originalTransactionId: transactionId }))
      .unwrap()
      .then(() => {
        dispatch(fetchAuthorizationDetails({ id: transactionId }));
        setModalOpen(false);
        setSelectedIndex(undefined);
      })
      .catch((error: any) => {
        setErrorMessage(error?.message ? error.message : error?.code);
        setErrorMessageType('void');
        setModalVoidOpen(false);
        setModalErrorOpen(true);
      });
  };

  const maxRefundAmountDecimalsValidation = (value: number): boolean => {
    return countDecimalPlaces(value) <= 2;
  };

  const amountValidations = {
    maxRefundAmountDecimals: maxRefundAmountDecimalsValidation,
  };

  useEffect(() => {
    if (RefundType.REFUND === refundType?.label) {
      setValue('amount', null as unknown as number);
    }
  }, [refundResult, refundType?.label, setValue]);

  const capitalizeFirstLetter = (str: string): string =>
    str
      .replaceAll(/(_)/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

  const isPartialRefund = () => {
    return (
      refundType &&
      (refundType as ItemAttributes).label === RefundType.PARTIAL_REFUND
    );
  };

  return (
    <>
      <div className="relative flex flex-col h-full bg-gray-200 ">
        <div className="flex justify-around items-center">
          <PageHeader title="Authorization" />
        </div>
        <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
          <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
            <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
              <div>
                <Button onClick={backButton} variant="outlined">
                  Back
                </Button>
              </div>
              {!isDetailsLoading &&
              !itemNotFound &&
              isAdmin &&
              optionsAuthAction.length ? (
                <>
                  {optionsAuthAction.length === 1 ? (
                    <Button
                      variant="contained"
                      onClick={optionsAuthAction[0]?.onActionClick}
                    >
                      {optionsAuthAction[0]?.actionName}
                    </Button>
                  ) : (
                    <>
                      <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="Button group with a nested menu"
                      >
                        <Button
                          onClick={handleClick}
                          disabled={selectedIndex === undefined}
                        >
                          {selectedIndex !== undefined
                            ? optionsAuthAction[selectedIndex]?.actionName
                            : 'Select Action'}
                        </Button>
                        <Button
                          size="small"
                          aria-controls={open ? 'split-button-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        sx={{ zIndex: 1 }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom'
                                  ? 'center top'
                                  : 'center bottom',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                  {optionsAuthAction
                                    .filter((value) => value.isAction)
                                    .map((value) => value.actionName)
                                    .map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        selected={index === selectedIndex}
                                        onClick={(event) =>
                                          handleMenuItemClick(event, index)
                                        }
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  )}
                </>
              ) : null}
            </div>
            {itemNotFound && (
              <div className="flex col-span-8 l2g:col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="-mt-8 w-full sm:w-auto">
                    <div className="flex flex-col items-center justify-center justify-items-center w-40 h-16 rounded-md ml-3 box-shadow-nero box-bg-nero-300">
                      <div className="border rounded-lg">
                        <AccountBalanceIcon
                          fontSize="large"
                          sx={{ color: '#fff' }}
                        />
                      </div>
                      <div className="text-xs text-white ml-1">No Details</div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                    <div>No such log</div>
                  </div>
                </div>
              </div>
            )}
            {isDetailsLoading && (
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                  <div className="w-full">
                    <RectLoader width={150} height={18} />
                  </div>
                </div>
              </div>
            )}
            {!isDetailsLoading && !itemNotFound && (
              <>
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="flex flex-wrap justify-between">
                      <div className="-mt-8 w-full sm:w-auto">
                        <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-medium-aquamarine box-shadow-medium-aquamarine">
                          <div>
                            <AccountBalanceIcon
                              fontSize="large"
                              sx={{ color: '#fff' }}
                            />
                          </div>
                          <div className="text-xs text-white ml-1">Details</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <Divider>
                        {t(
                          'transactionsManagement.transactions.details.transaction'
                        )}
                      </Divider>
                    </div>
                    <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 sm:p-3">
                      <ItemView
                        labelName="Merchant Name"
                        isNotSet
                        isUnknown
                        value={itemDetail.merchantName}
                      />
                      <ItemView
                        labelName="Transaction Type"
                        isNotSet
                        isUnknown
                        value={capitalizeFirstLetter(
                          itemDetail.transactionType
                        )}
                      />
                      <ItemView
                        labelName="Status"
                        isNotSet
                        isUnknown
                        value={
                          <AuthorizationStatusBadge
                            widthClass={'w-23'}
                            heightClass={'h-8'}
                            textSizeClass={'text-xxs'}
                            status={itemDetail.status}
                          >
                            {itemDetail.status.replaceAll(/(_)/g, ' ')}
                          </AuthorizationStatusBadge>
                        }
                      />
                      {[
                        AuthorizationStatus.COMPLETED,
                        AuthorizationStatus.VOIDED,
                        AuthorizationStatus.REVERSED,
                        AuthorizationStatus.REJECTED,
                      ].includes(AuthorizationStatus[itemDetail.status]) ? (
                        <div className="col-span-2">
                          <ItemView
                            labelName="Current status info"
                            isNotSet
                            isUnknown
                            value={`${itemDetail.status} on ${timeFormat(itemDetail.updatedAt)}`}
                          />
                        </div>
                      ) : null}
                      <ItemView
                        labelName="Transaction Amount"
                        isNotSet
                        isUnknown
                        value={itemDetail.transactionAmount.toFixed(2)}
                      />
                      {itemDetail.refundedAmount !== 0 ? (
                        <ItemView
                          labelName="Refunded Amount"
                          isNotSet
                          isUnknown
                          value={itemDetail.refundedAmount.toFixed(2)}
                        />
                      ) : null}
                      {itemDetail.incrementalAmount !== 0 ? (
                        <ItemView
                          labelName="Incremental Amount"
                          isNotSet
                          isUnknown
                          value={itemDetail.incrementalAmount.toFixed(2)}
                        />
                      ) : null}
                      {itemDetail.captureAmount ? (
                        <ItemView
                          labelName="Capture Amount"
                          isNotSet
                          isUnknown
                          value={itemDetail.captureAmount.toFixed(2)}
                        />
                      ) : null}
                      {itemDetail.partialReversalAmount !== 0 ? (
                        <ItemView
                          labelName="Partial Reversal Amount"
                          isNotSet
                          isUnknown
                          value={itemDetail.partialReversalAmount.toFixed(2)}
                        />
                      ) : null}
                      <ItemView
                        labelName="Transaction Currency"
                        isNotSet
                        isUnknown
                        value={itemDetail.transactionCurrency}
                      />
                      <ItemView
                        labelName="Submitted By"
                        isNotSet
                        isUnknown
                        value={itemDetail.user}
                      />
                      <ItemView
                        labelName="Submitted At"
                        isNotSet
                        isUnknown
                        value={timeFormat(itemDetail.transmissionDate)}
                      />
                      <ItemView
                        labelName="Updated At"
                        isNotSet
                        isUnknown
                        value={timeFormat(itemDetail.updatedAt)}
                      />
                      <ItemView
                        labelName="Transmission Date"
                        isNotSet
                        isUnknown
                        value={timeFormat(itemDetail.transmissionDate)}
                      />
                      <ItemView
                        labelName="Auth ID"
                        isNotSet
                        isUnknown
                        value={itemDetail.authId}
                      />
                      <ItemView
                        labelName="CVC Result Code"
                        isNotSet
                        isUnknown
                        value={itemDetail.ccvResult}
                      />
                      <ItemView
                        labelName="Response Code"
                        isNotSet
                        isUnknown
                        value={itemDetail.responseCode}
                      />
                      <ItemView
                        labelName="Additional Response Data"
                        isNotSet
                        isUnknown
                        value={itemDetail.additionalResponseData}
                      />
                      <ItemView
                        labelName="Merchant External Reference"
                        isNotSet
                        isUnknown
                        value={itemDetail.merchantExternalReference}
                      />
                      <ItemView
                        labelName="Merchant Ref Number"
                        isNotSet
                        isUnknown
                        value={itemDetail.merchantRefNumber}
                      />
                      <ItemView
                        labelName="STAN"
                        isNotSet
                        isUnknown
                        value={itemDetail.stan}
                      />
                      <ItemView
                        labelName="Merchant Order Number"
                        isNotSet
                        isUnknown
                        value={itemDetail.merchantOrderNumber}
                      />
                      <ItemView
                        labelName="AVS Response"
                        isNotSet
                        isUnknown
                        value={itemDetail.avsResponse}
                      />
                      <ItemView
                        labelName="POS Entry Mode"
                        isNotSet
                        isUnknown
                        value={itemDetail.posEntryMode}
                      />
                    </div>
                    <div className="mt-5">
                      <Divider>
                        {t(
                          'transactionsManagement.transactions.details.cardBillingAddress'
                        )}
                      </Divider>
                    </div>
                    <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-start justify-center justify-items-start sm:m-4 sm:p-3">
                      <ItemView
                        labelName="Name on card"
                        isNotSet
                        isUnknown
                        value={itemDetail.nameOnCard}
                      />
                      <ItemView
                        labelName="Card number"
                        isNotSet
                        isUnknown
                        value={itemDetail.cardNumber}
                      />
                      <ItemView
                        labelName="Card brand"
                        isNotSet
                        isUnknown
                        value={<CardBrandBadge status={itemDetail.cardType} />}
                      />
                      <ItemView
                        labelName="Card expiration"
                        isNotSet
                        isUnknown
                        value={`${itemDetail.cardExpirationMonth} / ${itemDetail.cardExpirationYear?.slice(-2)}`}
                      />
                      <ItemView
                        labelName="Billing Address"
                        isNotSet
                        isUnknown
                        value={itemDetail.billingAddress}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="flex flex-wrap justify-between">
                      <div className="-mt-8 w-full sm:w-auto">
                        <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-geraldine box-shadow-geraldine">
                          <div>
                            <ReceiptLongIcon
                              fontSize="large"
                              sx={{ color: '#fff' }}
                            />
                          </div>
                          <div className="text-xs text-white ml-1">
                            Related transactions
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" sm:mt-4 w-full">
                      {itemDetail.childTransaction?.length ? (
                        <TableContainer
                          component={Paper}
                          className="custom-scrollbar"
                          sx={{
                            maxHeight: 400,
                            overflow: 'auto',
                          }}
                        >
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="center">
                                  Type
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Status
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Amount
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Created At
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {itemDetail.childTransaction?.map((row, i) => (
                                <TableRow
                                  key={i}
                                  onClick={() => refundLinkRedirect(row.id)}
                                  hover
                                  role="button"
                                  tabIndex={-1}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    },
                                    cursor: 'pointer',
                                  }}
                                >
                                  <TableCell align="center">
                                    {capitalizeFirstLetter(row.type)}
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="flex flex-row items-center justify-center justify-items-center">
                                      <AuthorizationStatusBadge
                                        widthClass={'w-23'}
                                        heightClass={'h-8'}
                                        textSizeClass={'text-xxs'}
                                        status={row.status}
                                      >
                                        {row.status.replaceAll(/(_)/g, ' ')}
                                      </AuthorizationStatusBadge>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.amount.toFixed(2)} $
                                  </TableCell>
                                  <TableCell align="center">
                                    {timeFormat(row.createdAt)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <div className="flex flex-row items-center justify-center justify-items-center">
                          No Transactions
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2  6xl:col-span-10 h-auto bg-white border sm:mx-4 my-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="flex flex-wrap justify-between">
                      <div className="-mt-8 w-full sm:w-auto">
                        <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-bud box-shadow-bud">
                          <IconJSON />
                          <div className="text-xs text-white ml-1">
                            Description
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:m-4 sm:p-3">
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Request
                        </AccordionSummary>
                        <AccordionDetails>
                          <JsonViewer
                            value={itemDetail.request}
                            theme="dark"
                            rootName={false}
                            displayDataTypes={false}
                            maxDisplayLength={10000}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          Response
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <JsonViewer
                              value={itemDetail.response}
                              theme="dark"
                              rootName={false}
                              displayDataTypes={false}
                              maxDisplayLength={10000}
                            />
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalOpen}
        onClose={() => handleClickClose()}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleSubmit(save)}>
          <ModalDialog variant="outlined" role="alertdialog">
            <ModalClose onClick={() => handleClickClose()} />
            <DialogTitle>
              <ReportIcon color="warning" />
              Confirm Refund
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div className={isPartialRefund() ? 'h-[15rem]' : 'h-[8.9rem]'}>
                <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem]">
                  <p className="text-sm">
                    You are about to refund the transaction.
                    <br />
                    Remaining Amount {refundResult.toFixed(2)} $
                  </p>
                </div>
                <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem] pt-1">
                  <SelectController
                    label={
                      <RequiredFieldComponent
                        isRequired={false}
                        labelText="Select refund type"
                        className="font-normal tracking-wide text-sm text-gray-Shuttle"
                      />
                    }
                    height="2.5rem"
                    name="refundType"
                    control={control}
                    required
                    options={refundTypeItems}
                    closeMenuOnSelect
                    error={errors.refundType}
                  />
                </div>
                {isPartialRefund() ? (
                  <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem] pt-1">
                    <Input
                      label={
                        <RequiredFieldComponent
                          isRequired={false}
                          className="font-normal tracking-wide text-sm text-gray-Shuttle"
                          labelText={t(
                            'virtualTerminal.createDetails.refundAmount'
                          )}
                        />
                      }
                      isBottomError
                      className="h-10"
                      disabled={
                        !refundType || refundType?.label === RefundType.REFUND
                      }
                      type="number"
                      min={0.01}
                      max={refundResult}
                      step={0.01}
                      labelFontClassName="font-medium tracking-wide text-xs"
                      labelColorClassName="text-black"
                      error={errors.amount}
                      inputProps={register('amount', {
                        min: {
                          value: 0.01,
                          message: t('validations.maxRefundAmount', {
                            limit: refundResult,
                          }),
                        },
                        max: {
                          value: itemDetail.transactionAmount,
                          message: t('validations.maxRefundAmount', {
                            limit: refundResult,
                          }),
                        },
                        validate: {
                          maxRefundAmountDecimals:
                            amountValidations.maxRefundAmountDecimals,
                        },
                      })}
                    />
                  </div>
                ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <div className="w-full grid grid-cols-2 gap-2 p-2">
                <div className="w-full ">
                  <Button
                    color="primary"
                    variant="outlined"
                    className="w-full"
                    onClick={() => handleClickClose()}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-full">
                  <LoadingButton
                    type="submit"
                    className="w-full"
                    disabled={
                      !refundType ||
                      (refundType.label === RefundType.PARTIAL_REFUND &&
                        !amount)
                    }
                    color="primary"
                    variant="contained"
                    loading={isOperationLoading}
                  >
                    Submit Refund
                  </LoadingButton>
                </div>
              </div>
            </DialogActions>
          </ModalDialog>
        </form>
      </Modal>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalErrorOpen}
        onClose={() => handleClickErrorClose()}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <ModalClose onClick={() => handleClickErrorClose()} />
          <DialogTitle>
            <ErrorIcon color="error" />
            Error
          </DialogTitle>
          <Divider />

          <DialogContent>
            <div className="w-[15rem] m2d:w-[25rem] xxl:w-[35rem]">
              {errorMessageType === 'void' ? (
                <p>
                  We&#39;re sorry, but we were unable to void this transaction.
                </p>
              ) : (
                <p>
                  We&#39;re sorry, but we were unable to process your{' '}
                  {errorMessageType} at this time.
                </p>
              )}
              <p className="mt-2">
                Reason returned from Processor: {errorMessage}
              </p>
              <p className="mt-2">
                If you continue to experience issues, please contact our support
                team for assistance.
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleClickErrorClose()}
            >
              Close
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      {isTransactionCaptured ? (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={modalIncrementOpen}
          onClose={handleClickIncrementClose}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <form onSubmit={handleSubmit(saveIncrement)}>
            <ModalDialog variant="outlined" role="alertdialog">
              <ModalClose onClick={handleClickIncrementClose} />
              <DialogTitle>
                <IncrementAuth />
                Confirm authorization increment
              </DialogTitle>
              <Divider />

              <DialogContent>
                <div className="h-[8.7rem]">
                  <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem]">
                    <p className="text-sm">
                      You are about to Increment the authorization.
                      <br />
                      Authorization Amount{' '}
                      {itemDetail.transactionAmount.toFixed(2)}$
                    </p>
                    <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem]">
                      <Input
                        label={
                          <RequiredFieldComponent
                            isRequired={false}
                            className="font-normal tracking-wide text-sm text-gray-Shuttle"
                            labelText={t(
                              'virtualTerminal.createDetails.incrementAmount'
                            )}
                          />
                        }
                        isBottomError
                        className="h-10"
                        type="number"
                        min={0.0}
                        step={0.01}
                        labelFontClassName="font-medium tracking-wide text-xs"
                        labelColorClassName="text-black"
                        error={errors.incrementalAmount}
                        inputProps={register('incrementalAmount', {
                          required: true,
                          min: {
                            value: 0.0,
                            message: t('validations.minCaptureAmount'),
                          },
                          validate: {
                            maxRefundAmountDecimals:
                              amountValidations.maxRefundAmountDecimals,
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className="w-full grid grid-cols-2 gap-2 -mt-5">
                  <div className="w-full">
                    <Button
                      color="primary"
                      variant="outlined"
                      className="w-full"
                      onClick={handleClickIncrementClose}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="w-full">
                    <LoadingButton
                      type="submit"
                      className="w-full"
                      color="primary"
                      variant="contained"
                      loading={isOperationLoading}
                    >
                      Increment
                    </LoadingButton>
                  </div>
                </div>
              </DialogActions>
            </ModalDialog>
          </form>
        </Modal>
      ) : null}
      {isTransactionCaptured ? (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={modalReversalOpen}
          onClose={handleClickReversalClose}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <form onSubmit={handleSubmit(savePartialReversal)}>
            <ModalDialog variant="outlined" role="alertdialog">
              <ModalClose onClick={handleClickReversalClose} />
              <DialogTitle>
                <Rollback />
                Confirm Partial Reversal
              </DialogTitle>
              <Divider />

              <DialogContent>
                <div className="h-[8.7rem]">
                  <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem]">
                    <p className="text-sm">
                      You are about to partial reverse the authorization.
                      <br />
                      Authorization Amount{' '}
                      {itemDetail.transactionAmount.toFixed(2)}$
                    </p>
                    <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem]">
                      <Input
                        label={
                          <RequiredFieldComponent
                            isRequired={false}
                            className="font-normal tracking-wide text-sm text-gray-Shuttle"
                            labelText={t(
                              'virtualTerminal.createDetails.partialReversalAmount'
                            )}
                          />
                        }
                        isBottomError
                        className="h-10"
                        type="number"
                        min={0.0}
                        step={0.01}
                        labelFontClassName="font-medium tracking-wide text-xs"
                        labelColorClassName="text-black"
                        error={errors.reversalAmount}
                        inputProps={register('reversalAmount', {
                          required: true,
                          min: {
                            value: 0.0,
                            message: t('validations.minCaptureAmount', {
                              limit: captureResult,
                            }),
                          },
                          max: {
                            value: captureResult,
                            message: t('validations.maxRefundAmount', {
                              limit: captureResult,
                            }),
                          },
                          validate: {
                            maxRefundAmountDecimals:
                              amountValidations.maxRefundAmountDecimals,
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className="w-full grid grid-cols-2 gap-2 -mt-5">
                  <div className="w-full">
                    <Button
                      color="primary"
                      variant="outlined"
                      className="w-full"
                      onClick={handleClickReversalClose}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="w-full">
                    <LoadingButton
                      type="submit"
                      className="w-full"
                      color="primary"
                      variant="contained"
                      loading={isOperationLoading}
                    >
                      Reverse
                    </LoadingButton>
                  </div>
                </div>
              </DialogActions>
            </ModalDialog>
          </form>
        </Modal>
      ) : null}
      {isTransactionCaptured ? (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={modalCaptureOpen}
          onClose={() => handleClickCaptureClose()}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <form onSubmit={handleSubmit(saveCapture)}>
            <ModalDialog variant="outlined" role="alertdialog">
              <ModalClose onClick={() => handleClickCaptureClose()} />
              <DialogTitle>
                <SuccessVerify />
                Confirm Capture
              </DialogTitle>
              <Divider />

              <DialogContent>
                <div className="h-[9.9rem]">
                  <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem]">
                    <p className="text-sm">
                      You are about to capture the authorization.
                      <br />
                      Authorization Amount{' '}
                      {itemDetail.transactionAmount.toFixed(2)}$
                      <br />
                      Calculated Capture Amount{' '}
                      {Number(
                        itemDetail.transactionAmount +
                          itemDetail.incrementalAmount -
                          itemDetail.partialReversalAmount
                      ).toFixed(2)}
                      $
                    </p>
                    <div className="w-[15rem] m2d:w-[20rem] xxl:w-[25rem]">
                      <Input
                        disabled={!isCaptureEditable}
                        label={
                          <FormGroup>
                            <FormControlLabel
                              className="pl-2"
                              control={
                                <Checkbox
                                  size="small"
                                  checked={isCaptureEditable}
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={t(
                                'virtualTerminal.createDetails.captureAmount'
                              )}
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  color: '#2F323A',
                                  fontSize: '14px',
                                  fontFamily: [
                                    'Gothic A1',
                                    'system-ui',
                                    '-apple-system',
                                    'BlinkMacSystemFont',
                                    '"Segoe UI"',
                                    'Roboto',
                                    '"Helvetica Neue"',
                                    'Arial',
                                    '"Noto Sans"',
                                    'sans-serif',
                                    '"Apple Color Emoji"',
                                    '"Segoe UI Emoji"',
                                    '"Segoe UI Symbol"',
                                    '"Noto Color Emoji"',
                                  ].join(','),
                                },
                              }}
                            />
                          </FormGroup>
                        }
                        isBottomError
                        className="h-10"
                        type="number"
                        min={0.0}
                        step={0.01}
                        labelFontClassName="font-medium tracking-wide text-xs"
                        labelColorClassName="text-black"
                        error={errors.captureAmount}
                        inputProps={register('captureAmount', {
                          min: {
                            value: 0.0,
                            message: t('validations.minCaptureAmount', {
                              limit: captureResult,
                            }),
                          },
                          validate: {
                            maxRefundAmountDecimals:
                              amountValidations.maxRefundAmountDecimals,
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className="w-full grid grid-cols-2 gap-2 -mt-5">
                  <div className="w-full">
                    <Button
                      color="primary"
                      variant="outlined"
                      className="w-full"
                      onClick={() => handleClickCaptureClose()}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="w-full">
                    <LoadingButton
                      type="submit"
                      className="w-full"
                      color="primary"
                      variant="contained"
                      loading={isOperationLoading}
                    >
                      Capture
                    </LoadingButton>
                  </div>
                </div>
              </DialogActions>
            </ModalDialog>
          </form>
        </Modal>
      ) : null}
      {isTransactionVoid ? (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={modalVoidOpen}
          onClose={() => handleClickVoidClose()}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ModalDialog variant="outlined" role="alertdialog">
            <ModalClose onClick={() => handleClickVoidClose()} />
            <DialogTitle>
              <VoidVerify />
              Confirm Void
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div className="w-[15rem] m2d:w-[25rem] xxl:w-[35rem]">
                <p>
                  You are about to void this transaction. Please confirm this
                  action.
                </p>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="w-full grid grid-cols-2 gap-2 p-2">
                <div className="w-full">
                  <Button
                    color="primary"
                    variant="outlined"
                    className="w-full"
                    onClick={() => handleClickVoidClose()}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="w-full">
                  <LoadingButton
                    onClick={saveVoid}
                    className="w-full"
                    color="primary"
                    variant="contained"
                    loading={isOperationLoading}
                  >
                    Confirm
                  </LoadingButton>
                </div>
              </div>
            </DialogActions>
          </ModalDialog>
        </Modal>
      ) : null}
    </>
  );
};

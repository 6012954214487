import { createAsyncThunk } from '@reduxjs/toolkit';

import { userResource } from './api';
import {
  getAppliedFilters,
  getPage,
  getRowsPerPage,
  getSort,
} from './selectors';
import { LoadItems } from 'entities';
import { listRequestPayload, UpdateClientCorporateRequest } from './utils';
import { RootStateType } from 'store/store';

export const loadItems: any = createAsyncThunk(
  'umUserList/loadItems',
  async (options: LoadItems, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const page = getPage(getState() as RootStateType);
      const sort = getSort(getState() as RootStateType);
      const rowsPerPage = getRowsPerPage(getState() as RootStateType);
      const appliedFilters = getAppliedFilters(getState() as RootStateType);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...(options || {}),
        ...listRequestPayload(appliedFilters),
      };

      return await userResource.secretKeyList(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateClientCorporate: any = createAsyncThunk(
  'umUserList/updateClientCorporate',
  async (request: UpdateClientCorporateRequest) => {
    try {
      await userResource.updateClientCorporate(request);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilterSelect } from 'components/filters/FilterSelect';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Input } from 'components/inputs';
import {
  merchantList,
  transactionStatusList,
  transactionTypeList,
} from 'api/merchants';
import { FilterAuthorizationCode } from './FilterAuthorizationCode';
import React, { useRef } from 'react';
import {
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from '../../unmatchedTransactionSlice';
import { getAppliedFilters, getFilters } from '../../selectors';
import { cardBrands, defaultFilters, Filters as FilterList } from '../../utils';
import { useFilterEffect } from 'utils/useFilterEffect';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { countDecimalPlaces } from '../../../authorizations/utils';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const appliedFilters = useAppSelector(getAppliedFilters);
  const filters = useAppSelector(getFilters);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<any>({ mode: 'onBlur', defaultValues: appliedFilters });

  const allValues = watch();
  const previousValues = useRef<FilterList>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const resetFilters = () => {
    dispatch(resetAllFilters());
    reset(defaultFilters);
  };

  const handleApplyFilters = (data: FilterList) => {
    if (data.transactionDate?.from && !data.transactionDate?.to) {
      const updatedTransactionDate = {
        ...data.transactionDate,
        to: data.transactionDate?.from,
      };
      setValue('transactionDate', updatedTransactionDate);
    }
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  const handleDateChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (start) {
      setDateFilter(callback, {
        from: start,
        to: null,
      });
    }

    if (start && end) {
      setDateFilter(callback, {
        from: start,
        to: end,
      });
    }
  };

  const filterComponentsList = {
    0: <FilterAuthorizationCode register={register} />,
    1: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t(
              'transactionsManagement.transactions.list.filters.cardLastFour'
            )}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        inputProps={{
          ...register('cardLastFour', {
            onChange: (e) => {
              const filteredValue = e.target.value
                .replace(/\D/g, '')
                .slice(0, 4);
              setValue('cardLastFour', filteredValue);
            },
          }),
        }}
      />
    ),
    2: (
      <FilterSelect
        label={t('transactionsManagement.transactions.list.filters.cardBrand')}
        name="cardBrand"
        control={control}
        options={cardBrands}
        isClearable={false}
      />
    ),
    3: (
      <FilterSelect
        label={t('transactionsManagement.transactions.list.filters.merchant')}
        name="merchants"
        control={control}
        loadOptions={merchantList}
        isClearable={false}
      />
    ),
    4: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t(
              'transactionsManagement.authorizations.list.filters.transactionAmount'
            )}
          />
        }
        isBottomError
        type="number"
        min={0.0}
        max={99999.99}
        step={0.01}
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        error={errors.transactionAmount}
        inputProps={{
          ...register('transactionAmount', {
            validate: {
              maxRefundAmountDecimals: (value: any) =>
                value ? countDecimalPlaces(value) <= 2 : true,
              minAmountFilter: (value: any) => value >= 0,
            },
          }),
        }}
      />
    ),
    5: (
      <DatePickerControl
        control={control}
        label={t(
          'transactionsManagement.transactions.list.filters.transactionDate'
        )}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH}
        footer={<></>}
        popoverWidth={260}
        placementPosition="bottom-end"
        name="transactionDate"
        maxDate={new Date()}
        title={t(
          'transactionsManagement.transactions.list.filters.transactionDate'
        )}
      />
    ),
    6: (
      <FilterSelect
        label={t(
          'transactionsManagement.transactions.list.filters.transactionStatus'
        )}
        name="transactionStatus"
        control={control}
        loadOptions={transactionStatusList}
        isClearable={false}
      />
    ),
    7: (
      <FilterSelect
        label={t(
          'transactionsManagement.transactions.list.filters.transactionType'
        )}
        name="transactionType"
        control={control}
        loadOptions={transactionTypeList}
        isClearable={false}
      />
    ),
  };

  const transactionHasFilter = Boolean(
    filters.authorizationCode?.length ||
      filters.cardLast4?.length ||
      filters.merchants?.length ||
      filters.cardBrand?.length ||
      filters.transactionStatus?.length ||
      filters.transactionType?.length ||
      filters.transactionDate?.from ||
      (filters.transactionAmount &&
        countDecimalPlaces(filters.transactionAmount) <= 2 &&
        filters.transactionAmount >= 0)
  );

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={resetFilters}
      isDisabledClear={!transactionHasFilter}
      isDisabledApply={!transactionHasFilter}
    />
  );
};

import { PageHeader } from 'components/PageHeader';
import { Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditOffIcon from '@mui/icons-material/EditOff';
import { RectLoader } from 'components/RectLoader';
import classNames from 'classnames';
import { LoadingButton } from '@mui/lab';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface MerchantDetailsInputProps {
  pageName: string;
  handleSubmit: any;
  itemNotFound: boolean;
  isDetailsLoading: boolean;
  detailId: any;
  backUrl: string;
  backErrorMessage: string;
  iconColor: string;
  isSubmitting: any;
  isDirty: any;
  componentUpdate: any;
  icon: any;
  iconText: string;
}

export const UpdateDetailsComponent: React.FC<MerchantDetailsInputProps> = ({
  pageName,
  handleSubmit,
  itemNotFound,
  isDetailsLoading,
  detailId,
  backUrl,
  backErrorMessage,
  iconColor,
  isSubmitting,
  isDirty,
  componentUpdate,
  icon,
  iconText,
}) => {
  const history = useHistory();
  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={pageName} />
      </div>
      <div className="h-full p-3 pt-5 overflow-auto custom-scrollbar">
        <form onSubmit={handleSubmit} className="w-full">
          <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
            {/*back bar*/}
            <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-2 rounded-md box-shadow-dark">
              <div>
                {itemNotFound || !detailId ? (
                  <Button
                    onClick={() => history.push(backUrl)}
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    {backErrorMessage}
                  </Button>
                ) : (
                  <Button
                    onClick={() => history.goBack()}
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Back
                  </Button>
                )}
              </div>
            </div>
            {(itemNotFound || !detailId) && (
              <div className="flex flex-row justify-between items-center col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 mt-5 p-2 rounded-md box-shadow-dark">
                <div className="w-full">
                  <div className="-mt-8">
                    <div className="flex flex-col items-center justify-center justify-items-center w-full sm:w-16 h-16 rounded-md sm:ml-3 box-shadow-nero box-bg-nero-300">
                      <div>
                        <EditOffIcon fontSize="large" sx={{ color: '#fff' }} />
                      </div>
                      <div className="text-xs text-white ml-1">No Data</div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center justify-items-start m-4 ">
                    <div>No data to update</div>
                  </div>
                </div>
              </div>
            )}
            {isDetailsLoading && (
              <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-2 6xl:col-span-10 h-auto bg-white border m-4 p-4 rounded-md box-shadow-dark">
                <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
                  <div className="w-full">
                    <RectLoader width={150} height={18} />
                  </div>
                </div>
              </div>
            )}
            {!isDetailsLoading && !itemNotFound && detailId && (
              <>
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 mt-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="-mt-8">
                      <div
                        className={classNames(
                          'flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3',
                          iconColor
                        )}
                      >
                        <div>{icon}</div>
                        <div className="text-xs text-white ml-1">
                          {iconText}
                        </div>
                      </div>
                    </div>
                    {componentUpdate}
                  </div>
                </div>
                <div className="flex col-span-8 xxl:col-span-12 6xl:col-start-4 6xl:col-span-6 h-auto bg-white border sm:mx-4 p-4 rounded-md box-shadow-dark">
                  <div className="w-full">
                    <div className="flex items-center justify-center justify-items-stretch">
                      <div className="mx-2 sm:w-3/12">
                        <LoadingButton
                          loading={isSubmitting}
                          loadingPosition="start"
                          disabled={!isDirty}
                          type="submit"
                          className="w-full"
                          variant="contained"
                          color="success"
                          startIcon={<BookmarkAddedIcon />}
                        >
                          <span className="text-xs sm:text-sm">
                            Save Changes
                          </span>
                        </LoadingButton>
                      </div>
                      <div className="mx-2 sm:w-3/12">
                        <Button
                          onClick={() => history.goBack()}
                          className="w-full"
                          variant="contained"
                          color="error"
                          disabled={isSubmitting}
                          startIcon={<CancelPresentationIcon />}
                        >
                          <span className="text-xs sm:text-sm">Cancel</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

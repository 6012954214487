import LockResetIcon from '@mui/icons-material/LockReset';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import { PASSWORD_LENGTH_MAX, PASSWORD_LENGTH_MIN } from 'validation/patterns';
import { PasswordInput } from 'components/inputs/PasswordInput';
import { changePassword, sendPasswordOTP } from 'domain/account/thunks';

import { repeatedValidation, sequenceValidation } from '../components/util';
import { PasswordHintInfo } from '../components/PasswordHintInfo';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState } from 'react';
import { Input } from '../../../components/inputs';
import MailLockIcon from '@mui/icons-material/MailLock';
import { useSelector } from 'react-redux';
import { getIsPasswordOTPLoading } from 'domain/account/selectors';

type ResetPasswordSchema = {
  newPassword: string;
  repeatedPassword: string;
  email: string;
  passwordOTP: string;
};

const passwordValidations = {
  minLength: PASSWORD_LENGTH_MIN,
  maxLength: PASSWORD_LENGTH_MAX,
  lowercase: (password: string) => /[a-z]/.test(password),
  uppercase: (password: string) => /[A-Z]/.test(password),
  digits: (password: string) => /[0-9]/.test(password),
  special: (password: string) => /\W|_/g.test(password),
  sequenceCharacters: sequenceValidation,
  repeatedCharacters: repeatedValidation,
};

export const FormResetPassword = ({
  token,
}: {
  onSubmit?: () => void;
  token: string;
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    reset,
  } = useForm<ResetPasswordSchema>({
    mode: 'onBlur',
  });

  const { t } = useTranslation();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isPasswordOTPLoading = useSelector(getIsPasswordOTPLoading);
  const [isInputField, setIsInputField] = useState(false);
  const [isActiveLink, setIsActiveLink] = useState(false);

  const handleButtonClick = () => {
    setIsActiveLink(false);
    if (token) {
      if (!executeRecaptcha) {
        return;
      }

      dispatch(sendPasswordOTP({ tokenCode: token }))
        .unwrap()
        .then(() => {
          setIsInputField(true);
          setTimeout(() => {
            setIsActiveLink(true);
          }, 60000);
        })
        .catch(() => {});
      return;
    }
    dispatch(sendPasswordOTP())
      .unwrap()
      .then(() => {
        setIsInputField(true);
        setTimeout(() => {
          setIsActiveLink(true);
        }, 60000);
      })
      .catch(() => {});
  };

  const handleSubmitCallback = async (data: ResetPasswordSchema) => {
    const trimData = {
      ...data,
      passwordOTP: data.passwordOTP.replace(/\s+/g, '').trim(),
    };

    if (token) {
      if (!executeRecaptcha) {
        return;
      }
      const tokenKey = await executeRecaptcha('formResetPassword');
      dispatch(
        changePassword({
          data: { ...trimData, token: token },
          header: {
            recaptcha: tokenKey,
            'Content-Type': 'application/json',
          },
        })
      )
        .unwrap()
        .then(() => {
          history.push('/login');
        })
        .catch(() => {
          reset({
            newPassword: '',
            passwordOTP: '',
            repeatedPassword: '',
          });
          setIsInputField(false);
        });
    } else {
      dispatch(
        changePassword({
          data: { ...data, token: token },
          header: {
            recaptcha: null,
            'Content-Type': 'application/json',
          },
        })
      )
        .unwrap()
        .then(() => {
          history.push('/login');
        })
        .catch(() => {
          reset({
            newPassword: '',
            passwordOTP: '',
            repeatedPassword: '',
          });
          setIsInputField(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className="w-full">
      <div className="mb-5">
        <div className="w-full">
          <PasswordInput
            label={
              <PasswordHintInfo
                minLength={passwordValidations.minLength}
                maxLength={passwordValidations.maxLength}
              />
            }
            error={errors.newPassword}
            inputProps={register('newPassword', {
              required: true,
              minLength: {
                value: passwordValidations.minLength,
                message: t('resetPasswordForm.passwordMinLengthHint', {
                  minLength: passwordValidations.minLength,
                }),
              },
              maxLength: {
                value: passwordValidations.maxLength,
                message: t('resetPasswordForm.passwordMaxLengthHint', {
                  maxLength: passwordValidations.maxLength,
                }),
              },
              validate: {
                passwordUppercaseHint: passwordValidations.uppercase,
                passwordLowercaseHint: passwordValidations.lowercase,
                passwordDigitHint: passwordValidations.digits,
                passwordSpecialHint: passwordValidations.special,
                sequenceCharacters: passwordValidations.sequenceCharacters,
                repeatedCharacters: passwordValidations.repeatedCharacters,
              },
            })}
          />
        </div>
      </div>
      <div className="mb-5">
        <PasswordInput
          label={t('resetPasswordForm.passwordRepeat')}
          error={errors.repeatedPassword}
          inputProps={register('repeatedPassword', {
            required: true,
            validate: {
              passwordConfirmation: (value) =>
                value === getValues().newPassword,
            },
          })}
        />
      </div>
      <div className="mb-5">
        {isInputField ? (
          <Input
            label={t('resetPasswordForm.passwordOTP')}
            error={errors.passwordOTP}
            subLabel="Resend OTP Password"
            subLabelLink={handleButtonClick}
            isActiveLink={isActiveLink}
            subLinkLoading={isPasswordOTPLoading}
            inputProps={register('passwordOTP', {
              required: true,
              validate: {
                passwordOTPEmpty: (value) => value.trim().length > 0,
              },
            })}
          />
        ) : (
          <LoadingButton
            loading={isPasswordOTPLoading}
            loadingPosition="start"
            type="button"
            onClick={handleButtonClick}
            className="w-full fade-out"
            variant="outlined"
            startIcon={<MailLockIcon />}
          >
            {t('resetPasswordForm.sendPasswordOTP')}
          </LoadingButton>
        )}
      </div>
      <LoadingButton
        loading={isSubmitting}
        loadingPosition="start"
        type="submit"
        disabled={!isInputField}
        color="success"
        className="w-full"
        variant="contained"
        startIcon={<LockResetIcon />}
      >
        {t('resetPasswordForm.resetPasswordSubmit')}
      </LoadingButton>
    </form>
  );
};

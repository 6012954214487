import api from 'api';

const authorizationsList = async (data: any) => {
  return api.post('/authorizations/list', data);
};

const merchantAuthorizationsList = async (data: any) => {
  return api.post('/merchant-portal/authorizations/list', data);
};

const fetchDetails = async (data: { id: string }) => {
  return api.post('/authorizations/get', data);
};

const fetchMerchantDetails = async (data: { id: string }) => {
  return api.post('/merchant-portal/authorizations/get', data);
};

export const statusList = () => {
  return api.post('dropdown/authorizations/status');
};

export const authorizationStatusList = async () => {
  try {
    const { data } = await statusList();

    return data.map((item: string) => ({
      label: item,
      value: item,
    }));
  } catch (e) {
    return [];
  }
};

export const authorizationsResource = {
  authorizationsList,
  merchantAuthorizationsList,
  fetchDetails,
  fetchMerchantDetails,
};

import { Input } from 'components/inputs';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import React from 'react';
import { FilterSelect } from 'components/filters/FilterSelect';
import {
  accountHolderTypeList,
  accountTypeList,
  conditionEnableResultList,
  conditionResultList,
  GatewaysValues,
  localeList,
  merchantTypeList,
  selectClassLabel,
  stateList,
  timeZoneList,
} from '../../utils';
import { countryCodeList } from 'api/merchants';
import { userNameNMIPattern } from '../../../../../validation/patterns';

interface MerchantDetailsInputProps {
  register: any;
  control: any;
  errors: any;
  merchantCountry: any;
  merchantGateway: any;
}

export const MerchantDetailsInputComponent: React.FC<
  MerchantDetailsInputProps
> = ({ register, errors, control, merchantCountry, merchantGateway }) => {
  const isNMI = merchantGateway === GatewaysValues.NMI;
  return (
    <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-3 items-center justify-items-start my-4 sm:m-4 sm:p-3">
      <div className="w-full">
        <Input
          label={<RequiredFieldComponent labelText="Name" />}
          placeholder="Name"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.name}
          inputProps={{
            ...register('name', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent isRequired={true} labelText="Currency" />
          }
          placeholder="Currency"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.currency}
          inputProps={{
            ...register('currency', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <FilterSelect
          name="merchantCountry"
          labelClassName={selectClassLabel}
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Country Code"
            />
          }
          control={{ ...control }}
          loadOptions={countryCodeList}
          closeMenuOnSelect
          isClearable={true}
          isMulti={false}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent isRequired={false} labelText="Address1" />
          }
          placeholder="Address1"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.address1}
          inputProps={{
            ...register('address1', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent isRequired={false} labelText="Address2" />
          }
          placeholder="Address2"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.address2}
          inputProps={{
            ...register('address2', {
              required: false,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={<RequiredFieldComponent isRequired={true} labelText="City" />}
          placeholder="City"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.city}
          inputProps={{
            ...register('city', {
              required: true,
            }),
          }}
        />
      </div>
      {merchantCountry && merchantCountry.value === 'US' && (
        <div className="w-full">
          <FilterSelect
            name="stateUS"
            placeholder="Select State"
            label={
              <RequiredFieldComponent isRequired={true} labelText="State" />
            }
            control={{ ...control }}
            labelClassName={selectClassLabel}
            options={stateList}
            closeMenuOnSelect
            isClearable={true}
            isMulti={false}
          />
        </div>
      )}
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent isRequired={true} labelText="Postal" />
          }
          placeholder="Postal"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.postal}
          inputProps={{
            ...register('postal', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={<RequiredFieldComponent isRequired={false} labelText="Url" />}
          placeholder="Url"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.url}
          inputProps={{
            ...register('url', {
              required: false,
            }),
          }}
        />
      </div>
      {isNMI && (
        <>
          <div className="w-full">
            <FilterSelect
              name="timezoneId"
              placeholder="Select Time zone"
              labelClassName={selectClassLabel}
              label={
                <RequiredFieldComponent
                  isRequired={true}
                  labelText="Time Zone"
                />
              }
              control={{ ...control }}
              options={timeZoneList}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText="Account Number"
                />
              }
              placeholder="Account Number"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.accountNumber}
              inputProps={{
                ...register('accountNumber', {
                  required: false,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  isRequired={true}
                  labelText="Routing Number"
                />
              }
              placeholder="Routing Number"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.routingNumber}
              inputProps={{
                ...register('routingNumber', {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  isRequired={true}
                  labelText="Username"
                  infoIconMessage="Username can't contain spaces or any special caracters, except dots"
                />
              }
              placeholder="Username"
              className="h-8 "
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.username}
              inputProps={{
                ...register('username', {
                  required: true,
                  validate: {
                    userNameNMIValidation: (value) =>
                      userNameNMIPattern.test(value),
                  },
                }),
              }}
            />
          </div>

          <div className="w-full">
            <Input
              label={
                <RequiredFieldComponent
                  isRequired={true}
                  labelText="Fee Plan"
                  infoIconMessage="Use an existing NMI Fee Plan"
                />
              }
              placeholder="Fee Plan"
              className="h-8"
              labelFontClassName="font-medium tracking-wide text-xxs"
              labelColorClassName="text-gray-700"
              error={errors.feePlan}
              inputProps={{
                ...register('feePlan', {
                  required: true,
                }),
              }}
            />
          </div>
          <div className="w-full">
            <FilterSelect
              name="locale"
              labelClassName={selectClassLabel}
              placeholder="Select Locale"
              label={
                <RequiredFieldComponent isRequired={false} labelText="Locale" />
              }
              control={{ ...control }}
              options={localeList}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
        </>
      )}
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Contact Firstname"
            />
          }
          placeholder="Contact Firstname"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.contactFirstName}
          inputProps={{
            ...register('contactFirstName', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Contact Lastname"
            />
          }
          placeholder="Contact Lastname"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.contactLastName}
          inputProps={{
            ...register('contactLastName', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Contact Phone"
            />
          }
          placeholder="Contact Phone"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.contactPhone}
          inputProps={{
            ...register('contactPhone', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <Input
          label={
            <RequiredFieldComponent
              isRequired={true}
              labelText="Contact Emailt"
            />
          }
          placeholder="Contact Email"
          className="h-8"
          labelFontClassName="font-medium tracking-wide text-xxs"
          labelColorClassName="text-gray-700"
          error={errors.contactEmail}
          inputProps={{
            ...register('contactEmail', {
              required: true,
            }),
          }}
        />
      </div>
      <div className="w-full">
        <FilterSelect
          labelClassName={selectClassLabel}
          name="isAvsEnabled"
          placeholder="Address Verification System (AVS)"
          label={
            <RequiredFieldComponent
              isRequired={false}
              labelText="Address Verification System (AVS)"
            />
          }
          control={{ ...control }}
          options={conditionEnableResultList}
          closeMenuOnSelect
          isClearable={false}
          isMulti={false}
        />
      </div>
      {isNMI && (
        <>
          <div className="w-full">
            <FilterSelect
              labelClassName={selectClassLabel}
              name="accountType"
              placeholder="Select Account Type"
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText="Account Type"
                />
              }
              control={{ ...control }}
              options={accountTypeList}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="w-full">
            <FilterSelect
              name="accountHolderType"
              labelClassName={selectClassLabel}
              placeholder="Select Account Holder Type"
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText="Account Holder Type"
                />
              }
              control={{ ...control }}
              options={accountHolderTypeList}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="w-full">
            <FilterSelect
              name="merchantType"
              labelClassName={selectClassLabel}
              placeholder="Select Merchant Type"
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText="Merchant Type"
                />
              }
              control={{ ...control }}
              options={merchantTypeList}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="w-full">
            <FilterSelect
              name="captureHigherThanAuthed"
              labelClassName={selectClassLabel}
              placeholder="Capture Higher Than Authed"
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText="Capture Higher Than Authed"
                />
              }
              control={{ ...control }}
              options={conditionResultList}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
          <div className="w-full">
            <FilterSelect
              name="allowPaymentFacilitatorFields"
              labelClassName={selectClassLabel}
              placeholder="Allow Payment Facilitator Fields"
              label={
                <RequiredFieldComponent
                  isRequired={false}
                  labelText="Allow Payment Facilitator Fields"
                />
              }
              control={{ ...control }}
              options={conditionResultList}
              closeMenuOnSelect
              isClearable={true}
              isMulti={false}
            />
          </div>
        </>
      )}
    </div>
  );
};
